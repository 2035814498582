import React from 'react'

import { RebootusPageLayout } from '@/components/rebootus/rebootusPageLayout'
import { tempNewsList } from '@/constants/news/rebootus'
import { RebootusHead } from '@/components/rebootus/rebootusHead'
import { NewsList } from '@/components/common/news'

export default function RebootusNews(): JSX.Element {
  return (
    <>
      <RebootusHead title="株式会社Rebootus(Reboo+)" url="/group/rebootus" />
      <RebootusPageLayout
        title="お知らせ"
        subTitle="NEWS"
        breadcrumbs="GROUP/Rebootus/NEWS"
        breadcrumbsPath="/group/rebootus/news"
      >
        <NewsList newsList={tempNewsList} isPage />
      </RebootusPageLayout>
    </>
  )
}
